import { Button } from "./ui/button";
import { Plus, Send } from "lucide-react";
import { Input } from "./ui/input";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import { useState } from "react";

interface AddItemDialogProps {
  className?: string;
  onItemAdded?: (text: string) => void;
  userId: string;
}

export function AddItemDialog({ className, onItemAdded, userId }: AddItemDialogProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [itemText, setItemText] = useState("");

  const handleSubmit = async (text: string) => {
    onItemAdded?.(text);
    setIsOpen(false);
    setItemText("");
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button className={className + " rounded-full w-10 h-10 p-0"} >
          <Plus />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Add Item</DialogTitle>
          <DialogDescription>Add a new minder item.</DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Input
              id="name"
              value={itemText}
              onChange={(event) => setItemText(event.target.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleSubmit(itemText);
                }
              }}
              className="col-span-4"
            />
          </div>
        </div>
        <DialogFooter>
          <Button type="submit" onClick={() => handleSubmit(itemText)}>
            <Send />
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}