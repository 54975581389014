import { requireAuth, useAuth } from "../../util/auth";
import { ThreadList } from "@/components/ThreadList";

function ThreadsPage() {
  const auth = useAuth();

  return (
    <main className="py-8 container">
      <ThreadList userId={auth?.user.uid} />
    </main>
  );
}

export default requireAuth(ThreadsPage);
