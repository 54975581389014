import { requireAuth, useAuth } from "../../util/auth";

import { NewList } from "@/components/NewList";
import { NowList } from "@/components/NowList";
import { NextList } from "@/components/NextList";
import SectionHeader from "@/components/SectionHeader";


const greetingTime = require("greeting-time");

function TodayPage() {
  const auth = useAuth();

  return (
    <>
      <header className="py-8 container">
        <div className="w-full grid grid-cols-2 gap-4 content-start items-center justify-center">
          <div className="col-span-2">
            <SectionHeader title={greetingTime(new Date())} />
          </div>
        </div>
      </header>

        <main className="container mb-12">
          <NewList userId={auth?.user.uid} />
          <NowList userId={auth?.user.uid} />
          <NextList userId={auth?.user.uid} />
        </main>

    </>
  );
}

export default requireAuth(TodayPage);
