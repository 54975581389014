import React from "react";
import BackgroundImage from "./BackgroundImage";

type SectionProps = {
    size?: "sm" | "md" | "lg";
    bgColor?: string;
    textColor?: string;
    bgImage?: string;
    bgImageOpacity?: number;
    bgImageRepeat?: boolean;
    className?: string;
    children: React.ReactNode;
    [key: string]: any; // For any other additional props passed down
};

function Section(props : SectionProps) {
  const {
    size,
    bgColor = "transparent",
    textColor,
    bgImage,
    bgImageOpacity,
    bgImageRepeat,
    className,
    children,
    ...otherProps
  } = props;

  return (
    <section
      className={
        "section relative" +
        (bgColor ? ` ${bgColor}` : "") +
        (textColor ? ` ${textColor}` : "") +
        (className ? ` ${className}` : "")
      }
      {...otherProps}
    >
      {bgImage && (
        <BackgroundImage
          image={bgImage}
          opacity={bgImageOpacity || 1}
          repeat={bgImageRepeat || false}
        />
      )}

      <div
        className={
          "[&>.container]:relative" +
          (size === "sm" ? " py-10" : "") +
          (size === "md" ? " py-10 md:py-20" : "") +
          (size === "lg" ? " py-10 md:py-32" : "")
        }
      >
        {props.children}
      </div>
    </section>
  );
}

export default Section;
