import Section from "./Section";
import { Link, useLocation, useNavigate } from "./../util/router";
import { useAuth } from "./../util/auth";

import { Settings, LogOut } from "lucide-react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import { ReactComponent as LogoSvg } from "../logo.svg";

import { AddItemDialog } from "@/components/AddItemDialog";
import { createIncoming } from "@/util/db";

function Navbar(props: any) {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const bestName = () => {
    if (auth?.user?.first_name) {
      return auth.user.first_name;
    }
    return auth?.user?.email?.split("@")[0];
  };

  const isActive = (path: string) => {
    return location.pathname === path
      ? "text-vermillion-500 font-medium"
      : "text-gray-300";
  };
  return (
    <Section bgColor="bg-black/80 sticky top-0  backdrop-blur-sm z-10">
      <div className="container py-4">
        <div className="flex justify-between">
          <Link
            to="/"
            className="inline-flex items-center space-x-2 font-bold text-lg tracking-wide text-white hover:text-gray-300"
          >
            <LogoSvg className="h-16 w-12" />
            <span className="hidden lg:block">Vermillion</span>
          </Link>
          <div className="flex space-x-1 flex-grow">
            <ul className="flex items-center w-full justify-end space-x-4">
              <li className="relative group">
                <AddItemDialog
                  className=""
                  userId={auth?.user?.uid}
                  onItemAdded={async (text) => {
                    await createIncoming({
                      owner: auth?.user.uid,
                      command_text: text,
                      context: {
                        datetime_user: new Date().toISOString(),
                      },
                    });
                  }}
                />
              </li>
              <li className="relative group">
                <Link to="/today" className={isActive("/today")}>
                  Today
                </Link>
              </li>
              <li className="relative group">
                <Link to="/threads" className={isActive("/threads")}>
                  Threads
                </Link>
              </li>
              <li className="relative group">
                <Link to="/lists" className={isActive("/lists")}>
                  Lists
                </Link>
              </li>

              {(!auth || !auth.user) && (
                <li className="relative group">
                  <Link to="/auth/signin" className="text-gray-300">
                    <span>Sign in</span>
                  </Link>
                </li>
              )}

              {auth && auth.user && (
                <li className="relative group">
                  <DropdownMenu>
                    <DropdownMenuTrigger className="text-gray-300">
                      {bestName()}&nbsp;⏷
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                      className="w-56"
                      align="end"
                      forceMount
                    >
                      <DropdownMenuItem className="text-gray-300 pointer-events-none">
                        {auth.user.email}
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem
                        onClick={() => {
                          navigate("/settings");
                        }}
                      >
                        <Settings className="mr-2 h-4 w-4" />
                        Settings
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => {
                          auth.signout();
                        }}
                      >
                        <LogOut className="mr-2 h-4 w-4"></LogOut>
                        Sign out
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default Navbar;
