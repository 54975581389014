import { Card, CardContainer, CardPrefix } from "./ui/card";
import {
  Thread,
  useListItemsByOwner,
  useThreadsAndEventsByOwner,
} from "@/util/db";
import { useState } from "react";
import { ThreadRow } from "./ThreadRow";
import { MoreThreadsRow } from "./MoreThreadsRow";
import { ListItemView } from "./ListItemView";

interface NowListProps {
  userId: string;
}

export function NowList({ userId }: NowListProps) {
  const { data: threads } = useThreadsAndEventsByOwner(userId) as {
    data: Thread[] | null;
  };
  const { data: listItems } = useListItemsByOwner(userId);

  const [showAll, setShowAll] = useState(false);
  const context = "Mobile, Home, 20 minutes free";

  return (
    <CardContainer className="max-w-sm mx-auto flex flex-col">
      <CardPrefix>
        <span className="text-red-500">Now</span>
        <span className="text-muted-foreground">&nbsp;&nbsp;{context}</span>
      </CardPrefix>
      <Card className="max-w-sm">
        {listItems
          ?.filter((item) => item.list_name?.toUpperCase() === "TO WATCH")
          .slice(0, 1)
        .map((item) => (
            <ListItemView key={item.id} item={item} />
        ))}
      {listItems
        ?.filter((item) => item.list_name?.toUpperCase() === "TO READ")
        .slice(0, 1)
        .map((item) => (
            <ListItemView key={item.id} item={item} />
        ))}
        </Card>
    </CardContainer>
  );
}
