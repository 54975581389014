import { useState, useRef } from "react";
import { Button } from "./ui/button";
import { Card, CardContent } from "./ui/card";
import { Mic, Camera, CornerDownLeft, Keyboard } from "lucide-react";
import { createIncoming } from "@/util/db";

interface ThreadInputProps {
  userId: string;
  isFloating?: boolean;
}

export function ThreadInput({ userId, isFloating }: ThreadInputProps) {
  const [inputText, setInputText] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async () => {
    if (inputText.trim()) {
      await createIncoming({
        owner: userId,
        command_text: inputText.trim(),
      });
      setInputText("");
      setTimeout(() => {
          window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: 'smooth'
        });
      }, 100);
    } else {
      inputRef.current?.focus();
    }
  };

  const hasText = inputText.length > 0;
  const isFloatingStyle = isFloating || isFocused || hasText;

  return (
    <div>
      <Card className={`mt-4 border transition-all duration-200 ${
        isFloatingStyle
          ? 'bg-active-card -mx-2 -my-1 dark:bg-card shadow-[0px_6px_10px_4px_rgba(0,0,0,0.075),0px_2px_3px_0px_rgba(0,0,0,0.15)] border-card dark:border-card' 
          : 'bg-card shadow-[inset_0_2px_4px_rgba(0,0,0,0.06)]'
      }`}>
        <CardContent className="p-4">
          <div className="flex items-center gap-2">
            <div className="flex-1 relative">
              <input
                ref={inputRef}
                type="text"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                placeholder=""
                className="w-full p-3 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-transparent border-b-2 border-dotted border-gray-200"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit();
                  }
                }}
              />
            </div>
            
              <Button
              variant={hasText ? "default" : "ghost"}
              size="circle"
              onClick={handleSubmit}
              className={hasText ? "bg-vermillion-500" : ""}
              >
                {inputText.length > 0 ? (
                  <CornerDownLeft className="h-5 w-5" />
                ) : (
                  <Keyboard className="h-7 w-7 text-muted-foreground" />
                )}
                
              </Button>
            

            <Button
              variant="ghost"
              size="icon"
              className="hover:bg-gray-100"
              onClick={() => {
                console.log("Microphone clicked");
              }}
            >
              <Mic className="h-7 w-7 text-muted-foreground" />
            </Button>
            <Button
              variant="ghost"
              size="icon"
              className="hover:bg-gray-100"
              onClick={() => {
                console.log("Camera clicked");
              }}
            >
              <Camera className="h-7 w-7 text-muted-foreground" />
            </Button>


          </div>
        </CardContent>
      </Card>
    </div>
  );
} 