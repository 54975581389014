import { useState } from "react";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Dialog, DialogContent, DialogTrigger } from "./ui/dialog";

interface ActionPromptProps {
  actionPreview: {
    text: string;
    image_url?: string;
  };
  userPrompt: {
    prompt_type: "confirm" | "text_input";
    label?: string;
  };
  onConfirm: (value?: string) => void;
  onCancel: () => void;
}

export function ActionPrompt({ actionPreview, userPrompt, onConfirm, onCancel }: ActionPromptProps) {
  const [inputValue, setInputValue] = useState("");

  return (
    <div className="flex flex-col gap-4 p-4 border rounded-lg bg-gray-50">
      {/* Preview Section */}
      <div className="flex gap-4">
        {actionPreview.image_url && (
          <ImageWithEnlarge imageUrl={actionPreview.image_url} className="w-16 h-16 rounded" />
        )}
        <p className="whitespace-pre-wrap">{actionPreview.text}</p>
      </div>

      {/* Prompt Section */}
      <div className="flex gap-2">
        {userPrompt.prompt_type === "text_input" ? (
          <div className="flex flex-col gap-2 w-full">
            {userPrompt.label || "Enter your response"}
            <Input
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  onConfirm(inputValue);
                }
              }}
            />
            <Button variant="outline" onClick={() => onConfirm(inputValue)}>Submit</Button>
            <Button variant="outline" onClick={onCancel}>Cancel</Button>
          </div>
        ) : (
          <div className="flex gap-2">
            <Button onClick={() => onConfirm()}>Confirm</Button>
            <Button variant="outline" onClick={onCancel}>Cancel</Button>
          </div>
        )}
      </div>
    </div>
  );
}

function ImageWithEnlarge({ imageUrl, className }: { imageUrl: string, className?: string }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button className={className + " rounded-full p-0"} >
          <img
              src={imageUrl}
              alt="Action preview"
              className="w-full h-full object-cover"
            />
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-screen-lg">
        <img src={imageUrl} alt="Action preview" className="w-full h-full object-contain" />
      </DialogContent>
    </Dialog>
  );
}