import { Button } from "./ui/button";
import {
  Card,
  CardContainer,
  CardContent,
  CardHeader,
  CardPrefix,
} from "./ui/card";
import { hackRefreshIncoming, Thread, updateThreadEvent, useThreadsAndEventsByOwner, useIncomingByOwner, deleteIncoming } from "@/util/db";
import { useEffect, useRef, useState } from "react";
import { ActionPrompt } from "./ActionPrompt";
import { ThreadMenu } from "./ThreadMenu";
import { Trash2 } from "lucide-react";
import { ThreadInput } from "./ThreadInput";
import { formatDistanceToNow } from 'date-fns';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import "./ThreadList.css";

interface ThreadListProps {
  userId: string;
}

export function ThreadList({ userId }: ThreadListProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isFloating, setIsFloating] = useState(true);
  const {
    data: threads,
  } = useThreadsAndEventsByOwner(userId) as { data: Thread[] | null};
  const { data: incoming } = useIncomingByOwner(userId);

  // Add scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrolledToBottom = Math.ceil(scrollTop + windowHeight) >= documentHeight - 20;

      setIsFloating(!scrolledToBottom);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    setTimeout(() => {
      handleScroll();
    }, 100);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Hack to force refresh incoming when threads change. Supabase doesn't deliver
  // DELETE events with filters (e.g. by owner) so we just invalidate the incoming
  // query whenever anything changes in threads.
  useEffect(() => {
    hackRefreshIncoming();
  }, [threads]);

  // Add effect to scroll to bottom when incoming changes
  useEffect(() => {
    if (incoming && incoming.length > 0) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth'
      });
    }
  }, [incoming]);

  const renderThreads = () => {
    if (!threads) return null;

    let lastTimestamp: Date | null = null;
    return threads.map((thread) => {
      const currentTimestamp = new Date(thread.created_at);
      let timeText = null;

      if (!lastTimestamp ||
          (currentTimestamp.getTime() - lastTimestamp.getTime()) > 1800000) { // 30 minutes in milliseconds
        const now = new Date();
        const diffInHours = (now.getTime() - currentTimestamp.getTime()) / (1000 * 60 * 60);

         timeText = diffInHours > 2
          ? (() => {
              const today = new Date();
              today.setHours(0, 0, 0, 0);

              const threadDate = new Date(currentTimestamp);
              threadDate.setHours(0, 0, 0, 0);

              const yesterday = new Date(today);
              yesterday.setDate(yesterday.getDate() - 1);

              const options: Intl.DateTimeFormatOptions = {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
              };

              if (threadDate.getTime() === yesterday.getTime()) {
                return `Yesterday ${new Intl.DateTimeFormat('en-US', options).format(currentTimestamp)}`;
              } else if (threadDate.getTime() < today.getTime()) {
                options.weekday = 'long';
                return new Intl.DateTimeFormat('en-US', options).format(currentTimestamp);
              }

              return new Intl.DateTimeFormat('en-US', options).format(currentTimestamp);
            })()
          : formatDistanceToNow(currentTimestamp, { addSuffix: true });


      }
      lastTimestamp = currentTimestamp;

      return (
        <CSSTransition
          key={thread.id}
          timeout={300}
          classNames="thread"
        >
          <CardContainer>
            <CardPrefix className="text-sm font-mono-krypton text-muted-foreground align-right w-full justify-center">{timeText}</CardPrefix>
            <Card key={thread.id}>
              <ThreadMenu threadId={thread.id} />
              <div className="flex flex-row justify-between items-top">
                <CardContent className="rounded p-4 flex items-top align-top flex flex-col gap-2 text-muted-foreground" key={thread.id}>
                  <div className="font-bold min-h-12 mr-6">{thread.title}</div>
                  {thread.thread_events?.map((event: any) => {
                    if (event.type === "USER_PROMPT" && event.data.action_preview && !event.data.handled) {
                      return (
                        <ActionPrompt
                          key={event.id}
                          actionPreview={{
                            text: event.data.action_preview.text,
                            image_url: event.data.action_preview.image_url,
                          }}
                          userPrompt={{
                            prompt_type: event.data.user_prompt.prompt_type,
                            label: event.data.user_prompt.label,
                          }}
                          onConfirm={async (value) => {
                            event.data.handled = true;
                            event.data.user_prompt.value = value ? value : true;
                            event.data.user_prompt.confirmed = true;
                            await updateThreadEvent(event.id, {
                              text: "Confirmed",
                              data: event.data,
                            });
                          }}
                          onCancel={async () => {
                            event.data.handled = true;
                            event.data.user_prompt.confirmed = false;
                            await updateThreadEvent(event.id, {
                              text: "Cancelled",
                              data: event.data,
                            });
                          }}
                        />
                      );
                    }
                        
                    if (event.type === "COMMAND") {
                      if (event.text.toLowerCase() != thread.title.toLowerCase()) {
                        return <div key={event.id} className="text-sm opacity-50 text-muted-foreground font-mono-radon">{event.text}</div>;
                      }
                    } else if (event.type === "USER_PROMPT") {
                      if (event.text.toLowerCase().includes("cancelled")) {
                        return null;
                      }
                      return <div key={event.id} className="text-sm text-muted-foreground font-mono-krypton text-purple-500">{event.text}</div>;
                    } else {
                      return <div key={event.id} className="text-sm text-muted-foreground font-mono-radon">{event.text}</div>;
                    }

                  })}
                </CardContent>
              </div>
            </Card>
          </CardContainer>
        </CSSTransition>
      );
    });
  };

  return (
    <div>
      <div className="flex flex-col max-w-lg mx-auto thread-container">
        <TransitionGroup>
          {renderThreads()}
        </TransitionGroup>

        {/* Incoming Items Section */}
        {incoming && incoming.length > 0 && (
          <Card className="opacity-50">
            <CardContent>
              <TransitionGroup>
                {incoming.map((item) => (
                  <CSSTransition
                    key={item.id}
                    timeout={300}
                    classNames="thread"
                  >
                    <div className="rounded p-4 flex items-center gap-6">
                      <div className="flex flex-col gap-2">
                        <div>{item.command_text}</div>
                        <div className="text-sm text-gray-500">
                          Processing…
                        </div>
                      </div>
                      <Button className="ml-auto p-2" variant="ghost" onClick={() => deleteIncoming(item.id)}>
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </div>
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </CardContent>
          </Card>
        )}

        <div className="sticky bottom-12 z-10  mt-12">
          <ThreadInput userId={userId} isFloating={isFloating} />
        </div>
        <div ref={containerRef} />
      </div>
    </div>
  );
}

