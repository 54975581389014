import { CardRow } from "./ui/card";

interface MoreThreadsRowProps {
  total: number;
  shown: number;
  onClick: () => void;
  summary: string;
}

export function MoreThreadsRow({ total, shown, onClick, summary }: MoreThreadsRowProps) {
  if (total <= shown) return null;
  
  return (
    <CardRow className="cursor-pointer hover:bg-accent" onClick={onClick}>
      <div className="flex items-center gap-2 px-4 py-2 text-sm text-muted-foreground w-full">
        <span className="text-mutedforeground">{total - shown}&nbsp;more</span>
        {summary && (
            <span className="truncate">&nbsp;{summary}</span>
        )}
      </div>
    </CardRow>
  );
}

export default MoreThreadsRow; 