import { AuthProvider, IOSAuthInjectionProvider } from "./util/auth";
import { QueryClientProvider } from "./util/db";
import { Routes, Route, Router } from "./util/router";
import { ThemeProvider } from "./components/theme-provider";
import Navbar from "./components/Navbar";
import QuietPage from "./pages/QuietPage";
import WelcomePage from "./pages/WelcomePage";
import AuthPage from "./pages/AuthPage";
import SettingsPage from "./pages/SettingsPage";
import ThreadsPage from "./pages/ios/ThreadsPage";
import TodayPage from "./pages/ios/TodayPage";
import ListsPage from "./pages/ios/ListsPage";

import { AddItemDialog } from "@/components/AddItemDialog";
import { createIncoming } from "@/util/db";
import { useAuth } from "@/util/auth";

function App() {
  const userAgent = navigator.userAgent;
  const isNativeApp = userAgent.includes("Vermillion");
let auth = useAuth();
  return (
    <QueryClientProvider>
      <AuthProvider>
        <IOSAuthInjectionProvider>
          <ThemeProvider>
            <Router>
              
                <Routes>
                  {/* Suppress the navbar for these routes. */}
                  <Route path="/" element={<QuietPage />} />
                  {/* The meet urls were previously distributed publicly, so serve something quiet. */}
                  <Route path="/meet" element={<QuietPage />} />
                  <Route path="/meet/:person" element={<QuietPage />} />

                  <Route
                    path="*"
                    element={
                      <>
                    
                        {!isNativeApp && <Navbar bgColor="bg-vbg" />}
                        <Routes>
                          <Route path="/howdy" element={<WelcomePage />} />
                          <Route path="/auth/:type" element={<AuthPage />} />
                          <Route path="/dashboard" element={<TodayPage />} />
                          <Route path="/today" element={<TodayPage />} />
                          <Route path="/threads" element={<ThreadsPage />} />
                          <Route path="/lists" element={<ListsPage />} />
                          <Route path="/you" element={<SettingsPage />} />
                          <Route path="/settings" element={<SettingsPage />} />
                        </Routes>
                      </>
                    }
                  />
                </Routes>
            </Router>
          </ThemeProvider>
        </IOSAuthInjectionProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
