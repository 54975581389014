import { Card, CardContainer, CardPrefix } from "./ui/card";
import { Thread, useThreadsAndEventsByOwner } from "@/util/db";
import { useState } from "react";
import { ThreadRow } from "./ThreadRow";
import { MoreThreadsRow } from "./MoreThreadsRow";

interface NextListProps {
  userId: string;
}

export function NextList({ userId }: NextListProps) {
  const { data: threads } = useThreadsAndEventsByOwner(userId) as { data: Thread[] | null};
  const [showAll, setShowAll] = useState(false);
  const context = "in 25m";

  return (
    <CardContainer className="max-w-sm mx-auto">
      <CardPrefix>
        <span className="text-red-500">Next</span><span className="text-muted-foreground">&nbsp;&nbsp;{context}</span>
      </CardPrefix>
      <Card className="col-span-2 mb-8">
        {threads?.slice(0, showAll ? undefined : 3).map((thread) => (
          <ThreadRow key={thread.id} thread={thread} />
        ))}
        {!showAll && threads?.length && threads.length > 3 && (
          <MoreThreadsRow
            total={threads?.length ?? 0}
            shown={3}
            onClick={() => setShowAll(true)}
            summary={threads?.slice(3).map(t => t.title).filter(t => t.length > 0).join(", ") ?? ""}
          />
        )}
      </Card>
    </CardContainer>
  );
}
