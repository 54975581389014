import { requireAuth, useAuth } from "./../../util/auth";
import { ListsList } from "../../components/ListsList";
import SectionHeader from "@/components/SectionHeader";
function ListsPage() {
  const auth = useAuth();

  return (
    <main className="py-8 px-8 ">
      <SectionHeader title="Lists" />
      <ListsList userId={auth?.user.uid} />
    </main>
  );
}

export default requireAuth(ListsPage);
