import {
  useQuery,
  useQueryClient,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import { useEffect, useMemo } from "react";
import supabase from "./supabase";

// React Query client
const client = new QueryClient();

/**** USERS ****/

// Fetch user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid: string) {
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["users", { uid }],
    // Query function that fetches data
    () =>
      supabase.from("users").select(`*`).eq("id", uid).single().then(handle),
    // Only call query function if we have a `uid`
    { enabled: !!uid }
  );
}

// Fetch user data (non-hook)
// Useful if you need to fetch data from outside of a component
export function getUser(uid: string) {
  return supabase.from("users").select().eq("id", uid).single().then(handle);
}

// Update an existing user
// TODO(dps): write type for data
export async function updateUser(uid: string, data: any) {
  const response = await supabase
    .from("users")
    .update(data)
    .eq("id", uid)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["users", { uid }]);
  return response;
}

export function checkAllowlist(token: string) {
  return supabase
    .from("allowlist")
    .select()
    .eq("token", token)
    .single()
    .then((response) => {
      if (response.error) {
        return false;
      }
      return true;
    });
}

function useTableByOwner(
  tableName: string,
  owner: string,
  selectQuery: string = "*",
  orderBy: { column: string; ascending: boolean } = {
    column: "created_at",
    ascending: false,
  }
) {
  const queryClient = useQueryClient();
  const queryKey = useMemo(() => [tableName, { owner }], [tableName, owner]);

  const query = useQuery(
    queryKey,
    () =>
      Promise.resolve(
        supabase
          .from(tableName)
          .select(selectQuery)
          .eq("owner", owner)
          .order(orderBy.column, { ascending: orderBy.ascending })
          .then((res) => res.data)
      ),
    { enabled: !!owner }
  );

  useEffect(() => {
    if (!owner) return;

    const subscription = supabase
      .channel(`public:${tableName}`)
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: tableName,
          filter: `owner=eq.${owner}`,
        },
        () => {
          console.log(`${tableName} updated for ${owner}`);
          queryClient.invalidateQueries(queryKey);
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(subscription);
    };
  }, [owner, queryClient, queryKey, tableName]);

  return query;
}

function makeCreateFunction(table: string) {
  return async function create(data: any) {
    console.log(`create${table}`);
    const response = await supabase.from(table).insert([data]).then(handle);
    await client.invalidateQueries([table]);
    return response;
  };
}

function makeUpdateFunction(table: string) {
  return async function update(id: string, data: any) {
    const response = await supabase
      .from(table)
      .update(data)
      .eq("id", id)
      .then(handle);
    await client.invalidateQueries([table]);
    return response;
  };
}

function makeDeleteFunction(table: string) {
  return async function del(id: string) {
    const response = await supabase
      .from(table)
      .delete()
      .eq("id", id)
      .then(handle);
    await client.invalidateQueries([table]);
    return response;
  };
}

// Incoming
export type Incoming = {
  id: string;
  command_text: string;
};
export const createIncoming = makeCreateFunction("incoming");
export const deleteIncoming = makeDeleteFunction("incoming");
export function useIncomingByOwner(owner: string) {
  return useTableByOwner("incoming", owner) as { data: Incoming[] | null };
}
export async function hackRefreshIncoming() {
  await client.invalidateQueries(["incoming"]);
}

// ListItems
export type ListItem = {
  id: string;
  list_name: string;
  name: string;
  description: string;
  url: string;
  data: any;
  generated_summary_url?: string;
};
export const createListItem = makeCreateFunction("list_items");
export const deleteListItem = makeDeleteFunction("list_items");
export function useListItemsByOwner(owner: string) {
  return useTableByOwner("list_items", owner) as { data: ListItem[] | null };
}
export async function hackRefreshListItems() {
  await client.invalidateQueries(["list_items"]);
}

// Items
export type Item = {
  id: string;
  name: string;
};
export const createItem = makeCreateFunction("items");
export const updateItem = makeUpdateFunction("items");
export const deleteItem = makeDeleteFunction("items");
export function useItemsByOwner(owner: string) {
  return useTableByOwner("items", owner, "*", {
    column: "createdAt",
    ascending: false,
  }) as { data: Item[] | null };
}

// Reminders
export type Reminder = {
  id: string;
  text: string;
  deliver_at: string;
};
export const deleteReminder = makeDeleteFunction("reminders");
export function useRemindersByOwner(owner: string) {
  return useTableByOwner("reminders", owner, "*", {
    column: "deliver_at",
    ascending: false,
  }) as { data: Reminder[] | null; error: any };
}

// Threads
export type ThreadEvent = {
  id: string;
  text: string;
};

export type Thread = {
  id: string;
  title: string;
  created_at: Date;
  thread_events: ThreadEvent[];
};

export const deleteThread = makeDeleteFunction("threads");
export function useThreadsAndEventsByOwner(owner: string) {
  return useTableByOwner(
    "threads",
    owner,
    "id, title, created_at, thread_events(id, type, text, data)",
    { column: "created_at", ascending: true }
  ) as { data: Thread[] | null };
}

export const updateThreadEvent = makeUpdateFunction("thread_events");

/**** HELPERS ****/

// Get response data or throw error if there is one
function handle(response: any) {
  if (response.error) throw response.error;
  return response.data;
}

// React Query context provider that wraps our app
export function QueryClientProvider(props: any) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  );
}
