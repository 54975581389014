import { CardRow } from "./ui/card";
import { Thread } from "@/util/db";
import { ThreadMenu } from "./ThreadMenu";

interface ThreadRowProps {
  thread: Thread;
}

export function ThreadRow({ thread }: ThreadRowProps) {
  if (!thread.title) return null;
  return (
    <CardRow className="p-4">
      {thread.title}
      <ThreadMenu threadId={thread.id} />
    </CardRow>
  );
}

export default ThreadRow; 