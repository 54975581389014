import { Card, CardContainer, CardPrefix } from "./ui/card";
import { Thread, useThreadsAndEventsByOwner } from "@/util/db";
import { useState } from "react";
import { ThreadRow } from "./ThreadRow";
import { MoreThreadsRow } from "./MoreThreadsRow";

interface NewListProps {
  userId: string;
}

export function NewList({ userId }: NewListProps) {
  const { data: threads } = useThreadsAndEventsByOwner(userId) as { data: Thread[] | null};
  const [showAll, setShowAll] = useState(false);
  const shownCount = showAll ? threads?.length : 3;

  return (
    <CardContainer className="max-w-sm mx-auto">
      <CardPrefix>
        <span className="text-red-500">Inbox</span>
      </CardPrefix>
      <Card className="col-span-2 mb-8">
        {threads?.slice(0, shownCount).map((thread) => (
          <ThreadRow key={thread.id} thread={thread} />
        ))}
        {!showAll && (
          <MoreThreadsRow
            total={threads?.length ?? 0}
            shown={3}
            onClick={() => setShowAll(true)}
            summary={threads?.slice(3).map(t => t.title).filter(t => t.length > 0).join(", ") ?? ""}
          />
        )}
      </Card>
    </CardContainer>
  );
}
