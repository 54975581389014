import { useEffect, useState } from "react";
import Meta from "../components/Meta";
import { useRouter } from "./../util/router";
import { MagicLinkForm } from "@/components/MagicLinkForm";
import queryString from "query-string";
import { useNavigate } from "./../util/router";
import { useAuth } from "@/util/auth";

function AuthPage(props: any) {
  const router = useRouter();
  const [error, setError] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    if (auth?.user) {
      navigate("/today");
    }
  }, [auth, navigate]);

  useEffect(() => {
    const parseHash = queryString.parse(window.location.hash);
    // Example error URL
    // http://localhost:3000/auth/complete#error=access_denied&error_code=403&error_description=Email+link+is+invalid+or+has+expired
    if (parseHash.error) {
      setError(parseHash.error_description as string);
    }
  }, [router.query.type]);

  return (
    <>
      <Meta title="Auth" />
      {router.query.type === "signin" && <MagicLinkForm />}

      {router.query.type === "complete" && error && (
        <div className="container max-w-md">
          <h1 className="text-red-600 text-2xl">
            {error}
          </h1>
        </div>
      )}
    </>
  );
}

export default AuthPage;
