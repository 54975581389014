import { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { Switch } from "../components/ui/switch";
import { requireAuth, useAuth } from "./../util/auth";
import { updateUser } from "@/util/db";
import { apiRequest } from "../util/util";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import SectionHeader from "@/components/SectionHeader";

function SettingsPage() {
  const auth = useAuth();

  const [apiProfile, setApiProfile] = useState<any | null>(null);

  const [quickContactEmail, setQuickContactEmail] = useState<string | null>(
    auth?.user.setting_quick_contact?.email
  );
  const [quickContactName, setQuickContactName] = useState<string | null>(
    auth?.user.setting_quick_contact?.name
  );

  function refreshProfile() {
    apiRequest("profile", "GET").then((result) => {
      console.log("profile", result);
      setApiProfile(result);
    });
  }

  function googleLogout() {
    apiRequest("googlelogout", "GET").then((result) => {
      refreshProfile();
    });
  }

  function subscribeEmails() {
    apiRequest("subscribe-gmail", "POST").then((result) => {
      console.log("subscribe-gmail", result);
    });
  }

  useEffect(() => {
    refreshProfile();
  }, []);

  const saveQuickContact = () => {
    updateUser(auth?.user.id, {
      setting_quick_contact: {
        name: quickContactName,
        email: quickContactEmail,
      },
    });
  };

  return (
    <header className="h-screen  py-8 container">
      <div className="w-full grid grid-cols-2 gap-4 content-start items-center justify-center">
        <SectionHeader title="Settings" />
        <Card className="col-span-2">
          <CardHeader>
            <CardTitle>Notifications</CardTitle>
            <CardDescription>How vermillion will reach you.</CardDescription>
          </CardHeader>
          <CardContent className="space-y-2">
            <div className="p-4 flex items-top aligngap-2 gap-x-4">
              <div>
                Send emails to <span className="text-muted-foreground">{auth?.user.email}</span> for each
                new Minder item.
              </div>
              <Switch 
                checked={auth?.user.setting_email_reminders}
                onCheckedChange={() =>
                  updateUser(auth?.user.id, {
                    setting_email_reminders:
                      !auth?.user.setting_email_reminders,
                  })
                }
              />
            </div>
          </CardContent>
        </Card>

        <Card className="col-span-2">
          <CardHeader>
            <CardTitle>Quick contact</CardTitle>
            <CardDescription>
              You can pick a single contact to send quick notes to (e.g.
              Savannah). If you say “Savannah -anything-” it will send the
              -anything- message to Savannah
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-2">
            <div className="p-4 flex flex-col items-left gap-2 gap-x-6">
              <div className="flex items-top justify-between gap-x-4">
                <div className="flex-grow-0">Send emails to</div>
                <Switch
                  checked={auth?.user.setting_quick_contact_enabled}
                onCheckedChange={() =>
                  updateUser(auth?.user.id, {
                    setting_quick_contact_enabled:
                      !auth?.user.setting_quick_contact_enabled,
                  })
                }
                />
              </div>
              <Input
                className="h-10 text-lg max-w-s"
                placeholder="savannah@example.com"
                defaultValue={quickContactEmail || ""}
                onChangeCapture={(e) => {
                  setQuickContactEmail(e.currentTarget.value);
                }}
                onBlur={saveQuickContact}
              />{" "}
              when I say
              <Input
                className="h-10 text-lg max-w-s"
                placeholder="Savannah"
                defaultValue={quickContactName || ""}
                onChangeCapture={(e) =>
                  setQuickContactName(e.currentTarget.value)
                }
                onBlur={saveQuickContact}
              />
            </div>
          </CardContent>
        </Card>

        <Card className="col-span-2">
          <CardHeader>
            <CardTitle>Google Account</CardTitle>
          </CardHeader>
          <CardContent>
            {!apiProfile && <div className="p-4">Can't load OAUTH creds right now.</div>}
            {apiProfile && !apiProfile.hasGoogleCreds && (
              <div className="p-4 w-full md:w-1/2">
                <div className="p-6 rounded border border-shadow text-peach prose prose-a:text-peach max-w-none">
                  <h3 className="mb-4 text-palepeach">
                    Link your Google account
                  </h3>
                  <Button
                    onClick={() => {
                      window.location.href = "/googlelogin";
                    }}
                  >
                    <img
                      src={`https://uploads.divjoy.com/icon-google.svg`}
                      alt={"Google"}
                      className="w-5 h-5"
                    />
                  </Button>
                </div>
              </div>
            )}
            {apiProfile && apiProfile.hasGoogleCreds && (
              <div className="p-4 w-full md:w-1/2">
                <div className="p-6 rounded border border-shadow text-peach prose prose-a:text-peach max-w-none">
                  <h3 className="mb-4 text-palepeach">
                    Unlink your Google account
                  </h3>
                  <Button onClick={() => googleLogout()}>Unlink</Button>
                </div>
              </div>
            )}
            {apiProfile && apiProfile.hasGoogleCreds && (
              <div className="p-4 w-full md:w-1/2">
                <div className="p-6 rounded border border-shadow text-peach prose prose-a:text-peach max-w-none">
                  <h3 className="mb-4 text-palepeach">
                    Subscribe to all incoming emails
                  </h3>
                  <Button onClick={() => subscribeEmails()}>Subscribe</Button>
                </div>
              </div>
            )}
          </CardContent>
        </Card>

        <Card className="col-span-2 mt-48 text-muted-foreground bg-muted">
          <CardHeader>
            <CardTitle>Debug</CardTitle>
            <CardDescription>A peek at the innards</CardDescription>
          </CardHeader>
          <CardContent className="space-y-2" style={{ maxWidth: "90vw", whiteSpace: "pre-wrap", overflowX: "scroll" }}>
            <p>user</p>
            <code>{JSON.stringify(auth?.user)}</code>
            <p>apiProfile</p>
            <code>{JSON.stringify(apiProfile)}</code>
          </CardContent>
        </Card>
      </div>
    </header>
  );
}

export default requireAuth(SettingsPage);
