import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContainerTitle,
  CardRow,
  CardContainer,
} from "./ui/card";
import {
  BookAudioIcon,
  Book,
  Film,
  FileText,
  ShoppingCart,
  Music,
  Globe,
  Podcast,
  Video,
  Landmark,
  File,
  X,
} from "lucide-react";
import { ListItem, useListItemsByOwner } from "@/util/db";
import { Button } from "./ui/button";
import { Plus } from "lucide-react";

import { createListItem, deleteListItem } from "@/util/db";
import { AddListItemDialog } from "./AddListItemDialog";
import { ListItemView } from "./ListItemView";

interface ListsListProps {
  userId: string;
}

const groupBy = (array: any[], key: string) => {
  return array.reduce((result: any, currentValue: any) => {
    const group = currentValue[key];
    if (!result[group]) {
      result[group] = [];
    }
    result[group].push(currentValue);
    return result;
  }, {});
};

export function ListsList({ userId }: ListsListProps) {
  const { data: listItems } = useListItemsByOwner(userId);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [selectedList, setSelectedList] = React.useState("");

  const handleAddItem = async (data: {
    title: string;
    description: string;
    url: string;
    listName: string;
  }) => {
    try {
      await createListItem({
        owner: userId,
        url: data.url,
        name: data.title,
        description: data.description,
        list_name: data.listName,
      });
    } catch (error) {
      console.error("Error creating list item:", error);
      alert("Failed to add item. Please try again.");
    }
  };

  const openDialog = (listName: string) => {
    setSelectedList(listName);
    setDialogOpen(true);
  };

  // Filter out duplicates by URL+title before grouping
  const uniqueItems =
    listItems?.reduce((acc: ListItem[], current: ListItem) => {
      const exists = acc.find(
        (item) => item.url === current.url && item.name === current.name
      );
      if (!exists) {
        acc.push(current);
      }
      return acc;
    }, []) || [];

  const grouped = groupBy(uniqueItems, "list_name");

  return (
    <>
      <AddListItemDialog
        isOpen={dialogOpen}
        onOpenChange={setDialogOpen}
        onSubmit={handleAddItem}
        defaultListName={selectedList}
      />

      {listItems?.length === 0 && <div>You don't have any lists yet.</div>}
      <div className="flex flex-wrap gap-8 justify-center">
        {Object.keys(grouped).map((group) => (
          <Card key={group} className="w-96 self-start">
            <div className="flex justify-between items-center my-2 px-6 pr-2">
              <CardContainerTitle>{group}</CardContainerTitle>
              <Button
                size="circle"
                variant="ghost"
                onClick={() => openDialog(group)}
              >
                <Plus className="w-5 h-5" />
              </Button>
            </div>

            {grouped[group].slice(0, 10).map((item: ListItem) => (
              <ListItemView key={item.id} item={item} />
            ))}
          </Card>
        ))}
      </div>
    </>
  );
}
